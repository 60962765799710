.custom-border {
    border: 2px solid #000; /* Solid border around the entire row */
    border-radius: 5px; /* Slightly rounded corners */
  }
  
  .custom-border-right {
    border-right: 2px solid #000; /* Border between columns */
  }
  .custom-border-right-main {
    border-right: 2px solid #000; /* Border between columns */
    border-left: 2px solid #000;
  }
  
  .p-3 {
    padding: 15px; /* Padding for a cleaner look */
  }