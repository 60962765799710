/* Header.css */

.navbar-with-hanging-logo {
  position: relative;
  background-color: rgb(175, 8, 8) !important;
}

.navbar-brand-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.logo-container {
  position: absolute;
  top: 1px; /* Adjust this value to move the image down */
  left: 10px; /* Adjust this value to align the image horizontally */
}

.hanging-logo {
  width: 70px; /* Adjust size as needed */
  height: 100px;
  border-radius: 5%; /* Optional: to make the image round */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #fa0303;
  position: relative;
}

.hanging-logo::before {
  content: '';
  position: absolute;
  top: -20px; /* Adjust this value based on the hanging effect */
  left: 50%;
  width: 2px;
  height: 20px;
  background-color: #fff; /* Match the navbar color or any other color */
  transform: translateX(-50%);
}

.brand-name {
  margin-left: 60px; /* Adjust to avoid overlap with the logo */
  color: white;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .logo-container {
    top: 2px; /* Adjust this value for smaller screens */
    left: 20px; /* Adjust this value for smaller screens */
    transform: translateX(0); /* Adjust this value for smaller screens */
  }

  .hanging-logo {
    width: 40px; /* Adjust size as needed */
    height: 60px;
  }

  .brand-name {
    margin-left: 50px; /* Adjust to avoid overlap with the logo */
  }
}


@media (max-width: 576px) {
  .logo-container {
    top: 3px; /* Adjust this value for smaller screens */
    left: 10px; /* Adjust this value for smaller screens */
    transform: translateX(0); /* Adjust this value for smaller screens */
  }

  .hanging-logo {
    width: 30px; /* Adjust size as needed */
    height: 50px;
  }

  .brand-name {
    margin-left: 40px; /* Adjust to avoid overlap with the logo */
  }
}
